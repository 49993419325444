import { register, find } from '../../../../Javascript/Sysmex/Utils'
import Shepherd from 'shepherd.js'

const GuidedTour = (el) => {
  const steps = JSON.parse(el.dataset.steps)
  const progressIndicators = JSON.parse(el.dataset.progressindicators)
  const tooltipsContainer = find(`.${el.dataset.componentprefix}tooltips`, el)
  const navigation = document.querySelector('.sysmexMoleculeNavigation_navigationList')
  const tour = new Shepherd.Tour({
    useModalOverlay: false,
    stepsContainer: tooltipsContainer,

    defaultStepOptions: {
      cancelIcon: {
        enabled: true
      },
      classes: el.dataset.componentprefix + 'guidedTour',
      scrollTo: { behavior: 'smooth', block: 'center' }
    }
  })

  steps.forEach((step, index) => {
    tour.addStep({
      id: index,
      title: step.title,
      text: step.text + progressIndicators[index],
      attachTo: {
        element: step.element,
        on: step.position
      },
      when: {
        show: function () {
          if (window.dataLayer) {
            window.dataLayer.push({ event: 'GuidedTourEvent', 'guided-tour-event': step.identifier.toLowerCase() })
          }
        }
      },
      buttons: [
        {
          action () {
            return this.back()
          },
          classes: el.dataset.componentprefix + 'back-button ' + el.dataset.componentprefix + 'step' + index,
          text: el.dataset.backbutton
        },
        {
          action () {
            return this.next()
          },
          classes: el.dataset.componentprefix + 'next-button',
          text: el.dataset.nextbutton
        }
      ]
    })
  })

  tour.addStep({
    title: el.dataset.restarttourtitle,
    text: el.dataset.restarttourtext,
    attachTo: {
      element: el.dataset.restarttourelement,
      on: el.dataset.restarttourposition
    },
    classes: el.dataset.componentprefix + 'last-tooltip'
  })

  const startButton = find(`.${el.dataset.componentprefix}start-button`, el)
  const string = document.cookie
  const substring = 'sysmexMoleculeNotificationsToast_mysysmexguidedtour_guidedtour_'

  if (window.dataLayer) {
    if (!string.includes(substring)) {
      window.dataLayer.push({ event: 'GuidedTourEvent', 'guided-tour-event': 'guided-tour-start' })
    } else {
      window.dataLayer.push({ event: 'GuidedTourEvent', 'guided-tour-event': 'guided-tour-restart' })
    }
  }

  const notificationToast = document.querySelector('.sysmexMoleculeNotificationsToast_wrapper--isGuide')
  const startNavLink = document.querySelector("a[href='#restart-guidedtour']")
  startNavLink.addEventListener('click', () => {
    if (!notificationToast.classList.contains('sysmexMoleculeNotificationsToast_wrapperVisible') && !navigation.classList.contains('sysmexMoleculeNavigation_is-active')) {
      tour.start()
      navigation.classList.add('sysmexMoleculeNavigation_is-active')
    }
  })

  if (window.location.hash === '#restart-guidedtour') {
    tour.start()
    navigation.classList.add('sysmexMoleculeNavigation_is-active')
  }

  startButton.addEventListener('click', () => {
    tour.start()
    navigation.classList.add('sysmexMoleculeNavigation_is-active')
  })

  tour.on('cancel', onTourEnd)

  function onTourEnd () {
    const step = tour.getCurrentStep()
    onStepHide.call(step)
    navigation.classList.remove('sysmexMoleculeNavigation_is-active')
  }

  function onStepHide () {
    this.target.removeEventListener('click', this.cancel)
    navigation.classList.remove('sysmexMoleculeNavigation_is-active')
  }
}

register('GuidedTour', GuidedTour)
